import { ref } from 'vue'

export function usePillsTabLogic() {
  const activeTab = ref('')

  const activateTabHandler = (tab) => {
    activeTab.value = tab
  }

  const isTabActive = (tab) => {
    return tab === activeTab.value
  }

  return {
    activeTab,
    activateTabHandler,
    isTabActive,
  }
}
